const binding = { modules: {}, dataActions: {} };

    (binding.modules['ecomm-notif-banner'] = {
        c: () => require('partner/modules/ecomm-notif-banner/ecomm-notif-banner.tsx'),
        $type: 'contentModule',
        da: [],
        
        iNM: false,
        ns: '__local__',
        n: 'ecomm-notif-banner',
        p: '__local__',
        
        pdp: '',
        
        
        md: 'src/modules/ecomm-notif-banner'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };
                        export const viewDictionary = {};
                        viewDictionary['__local__|__local__|modules|ecomm-notif-banner|ecomm-notif-banner'] = {
                c: () => require('partner/modules/ecomm-notif-banner/ecomm-notif-banner.view.tsx'),
                cn: '__local__-__local__-ecomm-notif-banner'
            };
window.__bindings__ = window.__bindings__ || {};
window.__bindings__.viewDictionary = {
                        ...window.__bindings__.viewDictionary || {},
                        ...viewDictionary
                    };